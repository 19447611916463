import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`Tree component is a versatile user interface element designed to display hierarchical data in a tree-like structure.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tree/tree-guideline.svg",
      "alt": "anatomy navbar"
    }}></img>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Root Node: The top-level node from which the tree structure begins.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Parent Node: A node that contains child nodes, forming the hierarchy.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Child Node: Nodes that are nested within a parent node.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Expand/Collapse Icons: Icons or buttons that allow users to expand or collapse child nodes.`}</p>
      </li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The Tree component can be used to display hierarchical data in a variety of contexts, such as:`}</p>
    <ul>
      <li parentName="ul">{`File systems`}</li>
      <li parentName="ul">{`Product catalogs`}</li>
      <li parentName="ul">{`Organizational charts`}</li>
      <li parentName="ul">{`Navigation menus`}</li>
      <li parentName="ul">{`Breadcrumb trails`}</li>
    </ul>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>
        When you need to display a hierarchical structure of data in a compact
        and easy-to-navigate way.
      </li>
      <li>
        When you need to allow users to expand and collapse nodes to reveal or
        hide child nodes.
      </li>
      <li>When you need to allow users to select nodes to take actions on.</li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>When you need to display a limited number of items in a list.</li>
      <li>When you need to display items in a fixed order.</li>
      <li>When you need to allow users to sort or filter the items.</li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`To ensure that the Tree component is accessible to all users, consider the following guidelines:`}</p>
    <ul>
      <li parentName="ul">{`Make sure that the tree is keyboard accessible.`}</li>
      <li parentName="ul">{`Ensure that screen readers can interpret and announce the tree structure accurately.`}</li>
      <li parentName="ul">{`Make sure interactive elements (such as expand/collapse icons or selection indicators) have visible focus indicators for keyboard users.`}</li>
      <li parentName="ul">{`Use semantic HTML elements to build the tree structure.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Provide meaningful labels for each node to aid users in understanding the content." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tree/tree-a11y-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Didn’t provide meaningful labels for each node to aid users in understanding the content." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tree/tree-a11y-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`The Tree component can be used to display a variety of content types, such as:`}</p>
    <ul>
      <li parentName="ul">{`Text`}</li>
      <li parentName="ul">{`Images`}</li>
      <li parentName="ul">{`Icons`}</li>
      <li parentName="ul">{`Links`}</li>
      <li parentName="ul">{`Other components`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="A product catalog tree might display the names of product categories and products." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tree/tree-content-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="A product catalog tree didn’t display the names of product categories and products." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tree/tree-content-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      